<template>
  <div
      class="flex flex-row w-full items-center text-a-neutral-darkest shadow-sm"
  >
    <div class="flex flex-col w-6/12 md:w-5/12 relative">
      <BusinessesInstantSearchQuerySection
          ref="querySection"
          @searchBySelectedCategory="
          (category) => searchBySelectedCategory(category)
        "
          @searchBySelectedProduct="(product) => searchBySelectedProduct(product)"
          @searchBySelectedService="(service) => searchBySelectedService(service)"
          @searchBySelectedCustomProduct="
          (customProduct) => searchBySelectedCustomProduct(customProduct)
        "
          @searchBySelectedCustomService="
          (customService) => searchBySelectedCustomService(customService)
        "
          @searchBySelectedAmenity="(amenity) => searchBySelectedAmenity(amenity)"
      />
    </div>
    <div
        class="text-sm hidden md:flex flex-row items-center justify-center text-center w-2/12 border border-a-neutral-dim border-l-0 border-r-0 bg-white h-10 text-a-neutral-lightest"
    >
      <span class="font-semibold">Around</span>
      <Icon name="mdi:map-marker" />
    </div>
    <div class="flex flex-col w-6/12 md:w-4/12 relative border-l md:border-none">
      <InstantSearchLocationSection
          ref="locationSectionRef"
          @searchWithNewlySetLocation="
          searchBySelectedLocation
        "
          @searchFromLocation="
          gotoSearchPage()
        "
      />
    </div>
    <BaseButton
        primary-color-class="a-primary"
        width-class="w-2/12 rounded-r rounded-l-none h-10"
        text-color-class="text-a-neutral-darkest"
        @click.native="gotoSearchPage()"
        :loading="isSearching"
        loadingColor="#000000"
        loadingSize="7px"
    >
      <Icon name="mdi:magnify" />
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import {useBusinesses} from "~/stores/Businesses";
import type {
  BusinessesInstantSearchLocationSection,
  BusinessesSearchLocation,
  InstantSearchLocationSection
} from "~/types";


const locationSectionRef = ref<BusinessesInstantSearchLocationSection|null>(null);

const searchStore = useSearch();

const isSearching = computed(()=>{
  return searchStore.is_searching;
});

const currentSearchQuery = computed(()=>{
  return searchStore.current_search_query;
});

async function gotoSearchPage() {
  initiateSearch();
  await locationSectionRef.value?.adjustLocation();
  await navigateTo({
        name: "businesses-search",
        query: {
          q: currentSearchQuery.value == "" ? null : currentSearchQuery.value,
          ...searchStore.getBusinessesDefaultQueryParams(),
        },
      })
}

function searchBySelectedCategory(category) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      c: category.title,
      ci: category.id,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

function searchBySelectedProduct(product) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bp: product.title,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

function searchBySelectedService(service) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bs: service.title,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

function searchBySelectedCustomProduct(customProduct) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bcp: customProduct.title,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

function searchBySelectedCustomService(customService) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bcs: customService.title,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

function searchBySelectedAmenity(amenity) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      am: amenity.title,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

function searchBySelectedLocation() {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      ...useRoute().query,
      q: currentSearchQuery.value,
      ...searchStore.getBusinessesDefaultQueryParams(),
      page: undefined,
    },
  });
}

function initiateSearch() {
  searchStore.isSearching();
}

watch(()=>useRoute().fullPath,function () {
    searchStore.isNotSearching();
  },
  {
    deep: true,
        immediate: true,
  });
</script>

<style></style>
