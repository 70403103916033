<template>
  <div class="flex flex-none w-full border-b-2 border-a-neutral-dim bg-white pb-1">
    <div
        class="md:flex items-center justify-start w-full max-w-screen-2xl h-8 mx-auto px-4 hidden"
    >
      <BaseMultiLevelSmartDropdown
          :categories="businessCategories"
          :current-selected-category-id="currentSelectedCategoryId"
          isMultilevel
          @selectedCategory="
          (category) => {
            searchSuggestedBusinessCategory(category);
          }
        "
      />
      <!-- Individual Category Tiles -->
      <button
          v-for="category in businessCategories.slice(0, 5)"
          :key="category.id"
          class="flex items-center hover:text-a-secondary mx-4 cursor-pointer focus:outline-none font-semibold"
          :class="currentSelectedCategoryId===category.id.toString()?'text-a-secondary':'text-a-neutral-darkest'"
          @click="searchSuggestedBusinessCategory(category)"
      >
        {{ category.title }}
      </button>
      <!-- Individual Category Tiles Ends -->
      <!-- Categories Dropdown Ends-->
    </div>
    <div class="flex md:hidden w-full">
      <div
          class="swiper businessesNavCategoriesCarousel w-full h-16 bg-white categories"
      >
        <div class="swiper-wrapper">
          <div
              class="swiper-slide font-semibold text-2xl text-center w-full"
              v-for="category in businessCategories"
              :key="category.id"
              @click="searchSuggestedBusinessCategory(category)"
          >
            <div class="flex flex-row items-center justify-center h-full">
              <p class="shadow-xl mx-1 text-xs rounded-full px-1 py-1 w-full border-2"
                 :class="currentSelectedCategoryId===category.id.toString()?'bg-a-secondary-lightest border-a-secondary text-a-secondary':'bg-white border-a-neutral-light text-a-neutral-darkest '"
              >
                {{ category.title }}</p>
            </div>
          </div>
        </div>
        <Icon
            name="mdi:chevron-left"
            class="swiper-button-prev text-6xl text-a-neutral-darkest"
            :class="['categories-button-prev']"
        />
        <Icon
            name="mdi:chevron-right"
            class="swiper-button-next text-6xl text-a-neutral-darkest"
            :class="['categories-button-next']"
        />
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
//TODO: Must add goto Categories or when category slide is pressed

import type {BusinessCategoryTree} from "~/types";
import {Navigation} from "swiper/modules";

const searchStore = useSearch();

const businessCategories = await useBusinessesResourceDataManager().getBusinessesCategoriesTree() as BusinessCategoryTree[];

const currentSelectedCategoryId = computed(()=>{
  const selectedCategoryId = useRoute().query.ci;
  if(selectedCategoryId){
    return selectedCategoryId.toString();
  }
  return '';
})

function searchSuggestedBusinessCategory(category) {
  searchStore.setSearchQuery(category.title);
  navigateTo({
    name: "businesses-search",
    query: {
      c: category.title,
      ci: category.id,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  });
}

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.businessesNavCategoriesCarousel', {
    modules: [Navigation],
    direction: "horizontal",
    slidesPerView: 3,
    grabCursor: true,
    loop: false,
    navigation: {
      nextEl: `.categories-button-next`,
      prevEl: `.categories-button-prev`
    },
    effect: 'slide',
    allowTouchMove: true,
    observer: true,
  });
})

</script>

<style>
.categories-button-prev {
  @apply text-a-neutral-darkest;
}

.categories-button-next {
  @apply text-a-neutral-darkest;
}

</style>
