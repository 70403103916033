<template>
  <div
      v-click-outside="() => {
          searchStore.hideSearchSuggestions();
        }"
  >
    <div>
      <!-- Search Query Input -->
      <input
          type="text"
          class="text-xs md:text-sm h-10 w-full rounded-l bg-white border border-a-neutral-dim border-r-0 p-2 focus:outline-none font-semibold"
          placeholder="Search For Items..."
          v-model="localSearchQuery"
          @input="getInstantSearchResults"
      />
      <!-- Search Query Input Ends -->
      <!-- Search Suggestions Div -->
      <div
          :class="[
          'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
        ]"
          v-if="viewSearchSuggestionsList"
      >
        <BaseInstantSearchSuggestionsList
            v-if="businessCategorySearchSuggestions.length > 0"
            :suggestions="businessCategorySearchSuggestions"
            highlightingFieldName="title"
            listTitle="Categories"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedCategorySelection) =>
              searchSuggestedBusinessCategory(suggestedCategorySelection)
          "
        />
        <span  v-if="businessSearchSuggestions.length > 0" class="w-full border-b border-a-neutral-dim"></span>
        <BaseInstantSearchSuggestionsList
            v-if="businessSearchSuggestions.length > 0"
            :suggestions="businessSearchSuggestions"
            highlightingFieldName="name"
            listTitle="Businesses"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedBusinessSelection) =>
              gotoSuggestedBusinessPage(suggestedBusinessSelection)
          "
        />
        <span  v-if="businessProductSearchSuggestions.length > 0" class="w-full border-b border-a-neutral-dim"></span>
        <BaseInstantSearchSuggestionsList
            v-if="businessProductSearchSuggestions.length > 0"
            :suggestions="businessProductSearchSuggestions"
            highlightingFieldName="name"
            listTitle="Products"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedProductSelection) =>
              searchSuggestedBusinessProduct(suggestedProductSelection)
          "
        />
        <span v-if="businessServiceSearchSuggestions.length > 0" class="w-full border-b border-a-neutral-dim"></span>
        <BaseInstantSearchSuggestionsList
            v-if="businessServiceSearchSuggestions.length > 0"
            :suggestions="businessServiceSearchSuggestions"
            highlightingFieldName="name"
            listTitle="Services"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedServiceSelection) =>
              searchSuggestedBusinessService(suggestedServiceSelection)
          "
        />
        <span v-if="businessCustomProductSearchSuggestions.length > 0" class="w-full border-b border-a-neutral-dim"></span>
        <BaseInstantSearchSuggestionsList
            v-if="businessCustomProductSearchSuggestions.length > 0"
            :suggestions="businessCustomProductSearchSuggestions"
            highlightingFieldName="name"
            listTitle="Custom Products"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedCustomProductSelection) =>
              searchSuggestedBusinessCustomProduct(
                suggestedCustomProductSelection
              )
          "
        />
        <span v-if="businessCustomServiceSearchSuggestions.length > 0" class="w-full border-b border-a-neutral-dim"></span>
        <BaseInstantSearchSuggestionsList
            v-if="businessCustomServiceSearchSuggestions.length > 0"
            :suggestions="businessCustomServiceSearchSuggestions"
            highlightingFieldName="name"
            listTitle="Custom Services"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedCustomServiceSelection) =>
              searchSuggestedBusinessCustomService(
                suggestedCustomServiceSelection
              )
          "
        />
        <span v-if="businessAmenitySearchSuggestions.length > 0" class="w-full border-b border-a-neutral-dim"></span>
        <BaseInstantSearchSuggestionsList
            v-if="businessAmenitySearchSuggestions.length > 0"
            :suggestions="businessAmenitySearchSuggestions"
            highlightingFieldName="title"
            listTitle="Amenities"
            :currentSearchQuery="localSearchQuery"
            @suggestionSelected="
            (suggestedAmenitySelection) =>
              searchSuggestedBusinessAmenity(suggestedAmenitySelection)
          "
        />
      </div>
      <!-- Search Suggestions Div Ends -->
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>

const searchStore = useSearch();

const localSearchQuery = ref("");
const businessCategorySearchSuggestions = ref([]);
const businessSearchSuggestions = ref([]);
const businessProductSearchSuggestions = ref([]);
const businessServiceSearchSuggestions = ref([]);
const businessCustomProductSearchSuggestions = ref([]);
const businessCustomServiceSearchSuggestions = ref([]);
const businessAmenitySearchSuggestions = ref([]);
const noResultsFoundInSearchSuggestion = ref(false);

const emit = defineEmits([
  'searchBySelectedCategory',
  'searchBySelectedProduct',
  'searchBySelectedService',
  'searchBySelectedCustomProduct',
  'searchBySelectedCustomService',
  'searchBySelectedAmenity'
]);


function searchSuggestedBusinessCategory(category) {
  setQueryAndInitializeSearch(category.title);
  emit("searchBySelectedCategory", category);
}

function searchSuggestedBusinessProduct(product) {
  setQueryAndInitializeSearch(product.name);
  emit("searchBySelectedProduct", product);
}

function searchSuggestedBusinessService(service) {
  setQueryAndInitializeSearch(service.name);
  emit("searchBySelectedService", service);
}

function searchSuggestedBusinessCustomProduct(customProduct) {
  setQueryAndInitializeSearch(customProduct.name);
  emit("searchBySelectedCustomProduct", customProduct);
}

function searchSuggestedBusinessCustomService(customService) {
  setQueryAndInitializeSearch(customService.name);
  emit("searchBySelectedCustomService", customService);
}

function searchSuggestedBusinessAmenity(amenity) {
  setQueryAndInitializeSearch(amenity.title);
  emit("searchBySelectedAmenity", amenity);
}

function gotoSuggestedBusinessPage(business) {
  localSearchQuery.value = "";
  navigateTo({
    name: "businesses-slug",
    params: {
      slug: business.slug,
    },
  });
}

async function getInstantSearchResults() {
  if (localSearchQuery.value !== "" && localSearchQuery.value.length > 1) {
    searchStore.showSearchSuggestions();
    let results = await useMainBusinessesDataManager().getBusinessesInstantSearchResults(localSearchQuery.value)
    if (results) {
      fillSuggestionDataFromSearchResponse(results);
    } else {
      noResultsFoundInSearchSuggestion.value = true;
    }
  }
}


function setQueryAndInitializeSearch(query) {
  searchStore.hideSearchSuggestions();
  localSearchQuery.value = query;
  searchStore.setSearchQuery(query);
  searchStore.isSearching();
}

function fillSuggestionDataFromSearchResponse(searchResponse) {
  businessSearchSuggestions.value =
      searchResponse.business_results;
  businessCategorySearchSuggestions.value =
      searchResponse.business_categories_results;
  businessProductSearchSuggestions.value =
      searchResponse.business_products_results;
  businessServiceSearchSuggestions.value =
      searchResponse.business_services_results;
  businessCustomProductSearchSuggestions.value =
      searchResponse.business_custom_products_results;
  businessCustomServiceSearchSuggestions.value =
      searchResponse.business_custom_services_results;
  businessAmenitySearchSuggestions.value =
      searchResponse.business_amenities_results;
}

const viewSearchSuggestionsList = computed(() => {
  return (
      searchStore.is_showing_search_suggestions &&
      (businessSearchSuggestions.value.length > 0 ||
          businessCategorySearchSuggestions.value.length > 0 ||
          businessProductSearchSuggestions.value.length > 0 ||
          businessServiceSearchSuggestions.value.length > 0 ||
          businessServiceSearchSuggestions.value.length > 0 ||
          businessCustomProductSearchSuggestions.value.length > 0 ||
          businessCustomServiceSearchSuggestions.value.length > 0 ||
          businessAmenitySearchSuggestions.value.length > 0)
  );
});

watch(() => localSearchQuery.value, () => {
  if (localSearchQuery.value === "") {
    searchStore.hideSearchSuggestions();
  }
  searchStore.setSearchQuery(localSearchQuery.value)
});
watch(() => useRoute().query, () => {
  localSearchQuery.value =
      searchStore.current_search_query;
});

onMounted(() => {
  if (searchStore.current_search_query) {
    localSearchQuery.value =
        searchStore.current_search_query;
  } else {
    useRoute().query.q != null
        ? (localSearchQuery.value = useRoute().query.q as string)
        : (localSearchQuery.value = useRoute().query.c as string);
  }
})

</script>
<style></style>
